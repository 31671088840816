<template>
  <div class="g--flex-start g--pos-00 g--pad-l-2 g--bg-lingrad add-shadow" v-bind:style="{position: position}">
    <div v-on:click="nav_click('/')" class="nav-link-w">Home</div>
    <div v-on:click="nav_click('/about/contact')" class="nav-link-w">Contact</div>
    <div v-on:click="nav_click('/terms-of-use')" class="nav-link-w">Legal</div>
    <div v-on:click="nav_click('/about/requirements')" class="nav-link-w">Requirements</div>
    <div v-on:click="nav_click('/about/pricing')" class="nav-link-w">Pricing</div>
    <div v-on:click="nav_click('/about/gdpr')" class="nav-link-w">GDPR</div>
  </div>
</template>

<script>
export default {
  name: 'TopNavLinks',
  props: {
    position: String
  },
  methods: {
    nav_click: function (url) {
      // eslint-disable-next-line no-undef
      window.location = $WEB_SCRIPT_ROOT + url
    }
  }
}
</script>

<style scoped>
.nav-link-w {
    margin:2rem 1rem;
    /*width: 8rem;*/
    height: 6rem;
    color: var(--text-1);
    border: 0px solid var(--text-1-s);
    border-radius: 0rem;
    line-height: 6rem;
    text-align:center;
    font-weight: bold;
    text-decoration: unset;
  }
.nav-link-w:hover{
  /*background-color: var(--text-1-s);*/
  color: var(--text-1-s);
  text-decoration: unset;
  cursor: pointer;
  transition: background-color 0.2s ease,
              color 0.2s ease;
}
.add-shadow {
  border-bottom: 1px solid var(--text-1);
  /*box-shadow: 0px 3px 3px 0px var(--text-1);*/
}
</style>
