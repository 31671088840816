<template>
  <div>

    <BackgroundNetwork></BackgroundNetwork>

    <div class="container--heading g--bg-lingrad g--flex-around--col">

      <div  class="l--header-spacer"></div>

      <div class="g--flex-around g--pad-b-2 g--pad-t-10">
        <div class="l--dual_container">
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 842.55 227.99"><polygon class="cls-1" points="113.44 0 198.96 227.99 291.98 0 257.38 0 202.71 142.19 146.49 0 113.44 0"/><path class="cls-2" d="M364.39,159a39.08,39.08,0,0,1-68.33-7H401a65.71,65.71,0,0,0,1.58-14.87,70.47,70.47,0,1,0-12.78,40.4ZM332.16,98a39.3,39.3,0,0,1,36.1,24H296.06A39.3,39.3,0,0,1,332.16,98Z" transform="translate(-0.83)"/><circle class="cls-1" cx="772.12" cy="138.03" r="70.43"/><text class="cls-3" transform="translate(729.69 167.72)">in</text><path class="cls-2" d="M103.49,159a39.08,39.08,0,0,1-68.33-7h105a65.71,65.71,0,0,0,1.58-14.87,70.47,70.47,0,1,0-12.78,40.4ZM71.26,98a39.3,39.3,0,0,1,36.1,24H35.17A39.3,39.3,0,0,1,71.26,98Z" transform="translate(-0.83)"/><path class="cls-2" d="M553.18,139s0-.63,0-1a70.41,70.41,0,1,0-140.81.11V193h0a15,15,0,1,0,30,0h0V139.23a40,40,0,0,1,80,0V193h0a15.48,15.48,0,0,0,30.95,0h0V139h-0.21Z" transform="translate(-0.83)"/><path class="cls-2" d="M691,177.37l-25-18.27-0.34,0a39.89,39.89,0,0,1-6.32,7.06v-0.07a38.63,38.63,0,0,1-26.06,10.14A39.21,39.21,0,0,1,594.86,144h32.85a15,15,0,0,0,0-30H594.38V84h0c0-.14,0-0.28,0-0.42a15.58,15.58,0,1,0-31,2.09V144h-0.16A70.41,70.41,0,0,0,691,177.37Z" transform="translate(-0.83)"/></svg>
          <div class="animated fadeIn delay-1s cloud-icon">
            <i class="material-icons cloud-icon--font">cloud_upload</i>
          </div>
        </div>
        <div style="width:7vw" class="phone-no-show">&nbsp;</div>
        <div class="l--dual_container">
          <h1 class="g--text-left g--text-white">Roadshow Event Solutions</h1>
          <p class="g--text-c2">Manage and deliver professional financial roadshow and investor event schedules with a modernised and secure cloud solution.</p>
        </div>
      </div>

      <div class="btn-sign-up" v-on:click="nav_click('/register/organiser')">
        GET STARTED
      </div>

      <TopNavLinks v-bind:position="'absolute'" class="phone-no-show"></TopNavLinks>
      <TopNavLinksRight class="phone-no-show" v-if="backend_current_user" v-bind:position="'fixed'" v-bind:objects="[['Events', '/dashboard'],['Log out', '/logout']]"></TopNavLinksRight>
      <TopNavLinksRight class="phone-no-show" v-else                      v-bind:position="'fixed'" v-bind:objects="[['Sign Up', '/register/organiser'],['Login', '/login']]"></TopNavLinksRight>

      <div class="g--pad-t-2 g--flex-around--col phone-no-show">
        <div class="g--pad-t-2 l--quote g--flex-between g--text-white">
          <div style="width:70%; min-width:200px">" Eventin played a part in the great success of our recent multi-national roadshow. Coordination between five banks was seamless and all of our team had clear and detailed individualised schedules. We really appreciated it! "<br>
          <span style="font-size:1.6rem; font-style:normal;" class="g--text-c2">Marianne Lofthus - CFO and Director Capital Markets Division - Sparebanken Sør </span>
          </div>
          <div>
            <img src="@/static/images/icons/price_sor.png" width="120" height="120">
          </div>
        </div>

        <div class="g--pad-t-2 l--quote g--flex-between g--text-white">
          <div style="width:70%; min-width:200px">" Eventin is really impressive! Simple to use with immediate schedule updates. Updating and distributing unwieldy PDF schedule documents is now officially a thing of the past. "<br>
          <span style="font-size:1.6rem; font-style:normal;" class="g--text-c2">Götz Michl - Head of Funding and Debt Investor Relations - Deutsche Pfandbriefbank </span>
          </div>
          <div>
            <img src="@/static/images/icons/price_pbb.png" width="120" height="120">
          </div>
        </div>
      </div>
    </div>

    <div id="schedules-icons" class="container--banner l--light">

      <h1 style="margin-top: 0">Schedules Management</h1>

      <div class="g--flex-around  g--flex--align-start">
        <div class="g--flex-around--col banner-el--max-width banner-box">
          <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path class="cls-1" d="M19 5v2h-4V5h4M9 5v6H5V5h4m10 8v6h-4v-6h4M9 17v2H5v-2h4M21 3h-8v6h8V3zM11 3H3v10h8V3zm10 8h-8v10h8V11zm-10 4H3v6h8v-6z"/></svg>
          <div class="g--text-center" style="width:100%">
            <h2>Create + Input</h2>
            <p>
              Create a new event and add your contacts and companies to your workspace. Each new event needs less and less
              work as your personal database builds.
            </p>
            <p>
              <b>Quickly input schedule items</b>, such as meetings and travel arrangements, to the event using your contacts list.
            </p>
          </div>
        </div>

        <div class="g--flex-around--col banner-el--max-width banner-box">
          <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path class="cls-1" d="M12 22C6.49 22 2 17.51 2 12S6.49 2 12 2s10 4.04 10 9c0 3.31-2.69 6-6 6h-1.77c-.28 0-.5.22-.5.5 0 .12.05.23.13.33.41.47.64 1.06.64 1.67 0 1.38-1.12 2.5-2.5 2.5zm0-18c-4.41 0-8 3.59-8 8s3.59 8 8 8c.28 0 .5-.22.5-.5 0-.16-.08-.28-.14-.35-.41-.46-.63-1.05-.63-1.65 0-1.38 1.12-2.5 2.5-2.5H16c2.21 0 4-1.79 4-4 0-3.86-3.59-7-8-7z"/><circle class="cls-1" cx="6.5" cy="11.5" r="1.5"/><circle class="cls-1a" cx="9.5" cy="7.5" r="1.5"/><circle class="cls-1b" cx="14.5" cy="7.5" r="1.5"/><circle class="cls-1c" cx="17.5" cy="11.5" r="1.5"/></svg>
          <div class="g--text-center" style="width:100%">
            <h2>Visualise + Stylise</h2>
            <p>
              Add custom company logos and colours to <b>brand the look and feel</b> of your event schedules. You have enormous flexibility to change fonts and icons and logo sizes on your schedules.
            </p>
            <p>
              We automatically produce personalised schedules for each contact based on their scheduled items,
              or for combined groups of contacts, saving time for you.
            </p>
          </div>
        </div>

        <div class="g--flex-around--col banner-el--max-width banner-box">
          <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><circle class="cls-1" cx="6.18" cy="17.82" r="2.18"/><path class="cls-1" d="M4 4.44v2.83c7.03 0 12.73 5.7 12.73 12.73h2.83c0-8.59-6.97-15.56-15.56-15.56zm0 5.66v2.83c3.9 0 7.07 3.17 7.07 7.07h2.83c0-5.47-4.43-9.9-9.9-9.9z"/></svg>
          <div class="g--text-center" style="width:100%">
            <h2>Distribute + Update</h2>
            <p>
              Print PDFs and email them to your contacts, <b>or</b> email token URL links so contacts can <b>access
              their own personalised schedule</b> in the cloud on their device.
            </p>
            <p>
              Token links display the latest changes so you can ensure contacts have all the latest details available in realtime.
            </p>
          </div>
        </div>
      </div>

    </div>

    <div data-nosnippet id="gdpr" class="container--banner g--bg-primary">

      <h1 class="h1--white" style="margin-top: 0">Fully GDPR Compliant</h1>

      <div class="g--text-left">
        <h2 class="g--text-white g--text-left l--gdpr-header">You are the data controller</h2>
      </div>

      <div class="g--flex-start">
        <div class="g--flex-around">
          <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0zm0 0h24v24H0V0z"/><path class="cls-1" d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/></svg>
          <div class="gdpr-item-text">Article 6 of GDPR permits you to lawfully process, either privately or through third parties such as Eventin, personal information for legitimate business interests, such as being an event organiser producing a schedule for an
          upcoming event.</div>
        </div>
        <div class="g--flex-around">
          <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path class="cls-1" d="M18 11l5-5-5-5v3h-4v4h4v3zm2 4.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1z"/></svg>
          <div class="gdpr-item-text">You will have already informed your contacts their data is used for event coordination as well as other communications and business matters, in order to be complaint with Articles 13 and 14. This is necessary for your business, regardless, whether you use your own in house tools or choose Eventin.</div>
        </div>
        <div class="g--flex-around">
          <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path class="cls-1" d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/></svg>
          <div class="gdpr-item-text">Data entered into Eventin has extremely low sensitivity; only name, position and contact details. Often this is publicly available and you will have likely collected it from public sources and not the individuals themselves. This virtually eliminates any concerns regarding data breaches contained in Articles 32 through 36. However, we are very prudent and implement complaint security (see below).</div>
        </div>
        <div class="g--flex-around">
          <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" fill="none" d="M0 0h24v24H0z"/><g class="cls-1" fill-rule="evenodd" clip-rule="evenodd"><path class="cls-1" d="M9 17l3-2.94c-.39-.04-.68-.06-1-.06-2.67 0-8 1.34-8 4v2h9l-3-3zm2-5c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4"/><path d="M15.47 20.5L12 17l1.4-1.41 2.07 2.08 5.13-5.17 1.4 1.41z"/></g></svg>
          <div class="gdpr-item-text">Only you choose how schedules are distributed and you have full control to specify who has access to see the collated information. You can edit, erase and add new data at any time, and all access to data is cryptographically secured.</div>
        </div>
      </div>

      <div class="g--text-right">
        <h2 class="g--text-white g--text-right l--gdpr-header">We are your data processor</h2>
      </div>

      <div class="g--flex-end">
        <div class="g--flex-around">
          <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24"><path class="cls-1" d="M17.81 4.47c-.08 0-.16-.02-.23-.06C15.66 3.42 14 3 12.01 3c-1.98 0-3.86.47-5.57 1.41-.24.13-.54.04-.68-.2-.13-.24-.04-.55.2-.68C7.82 2.52 9.86 2 12.01 2c2.13 0 3.99.47 6.03 1.52.25.13.34.43.21.67-.09.18-.26.28-.44.28zM3.5 9.72c-.1 0-.2-.03-.29-.09-.23-.16-.28-.47-.12-.7.99-1.4 2.25-2.5 3.75-3.27C9.98 4.04 14 4.03 17.15 5.65c1.5.77 2.76 1.86 3.75 3.25.16.22.11.54-.12.7-.23.16-.54.11-.7-.12-.9-1.26-2.04-2.25-3.39-2.94-2.87-1.47-6.54-1.47-9.4.01-1.36.7-2.5 1.7-3.4 2.96-.08.14-.23.21-.39.21zm6.25 12.07c-.13 0-.26-.05-.35-.15-.87-.87-1.34-1.43-2.01-2.64-.69-1.23-1.05-2.73-1.05-4.34 0-2.97 2.54-5.39 5.66-5.39s5.66 2.42 5.66 5.39c0 .28-.22.5-.5.5s-.5-.22-.5-.5c0-2.42-2.09-4.39-4.66-4.39-2.57 0-4.66 1.97-4.66 4.39 0 1.44.32 2.77.93 3.85.64 1.15 1.08 1.64 1.85 2.42.19.2.19.51 0 .71-.11.1-.24.15-.37.15zm7.17-1.85c-1.19 0-2.24-.3-3.1-.89-1.49-1.01-2.38-2.65-2.38-4.39 0-.28.22-.5.5-.5s.5.22.5.5c0 1.41.72 2.74 1.94 3.56.71.48 1.54.71 2.54.71.24 0 .64-.03 1.04-.1.27-.05.53.13.58.41.05.27-.13.53-.41.58-.57.11-1.07.12-1.21.12zM14.91 22c-.04 0-.09-.01-.13-.02-1.59-.44-2.63-1.03-3.72-2.1-1.4-1.39-2.17-3.24-2.17-5.22 0-1.62 1.38-2.94 3.08-2.94 1.7 0 3.08 1.32 3.08 2.94 0 1.07.93 1.94 2.08 1.94s2.08-.87 2.08-1.94c0-3.77-3.25-6.83-7.25-6.83-2.84 0-5.44 1.58-6.61 4.03-.39.81-.59 1.76-.59 2.8 0 .78.07 2.01.67 3.61.1.26-.03.55-.29.64-.26.1-.55-.04-.64-.29-.49-1.31-.73-2.61-.73-3.96 0-1.2.23-2.29.68-3.24 1.33-2.79 4.28-4.6 7.51-4.6 4.55 0 8.25 3.51 8.25 7.83 0 1.62-1.38 2.94-3.08 2.94s-3.08-1.32-3.08-2.94c0-1.07-.93-1.94-2.08-1.94s-2.08.87-2.08 1.94c0 1.71.66 3.31 1.87 4.51.95.94 1.86 1.46 3.27 1.85.27.07.42.35.35.61-.05.23-.26.38-.47.38z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
          <div class="gdpr-item-text">We apply rigorous security protocols to data management, pursuant to Article 32. Your account is password protected and any personally identifiable information is encrypted before it is stored in our EU database servers. Cloud access to event schedules and contact information is via unique, cryptographically signed, access tokens which only you distribute.</div>
        </div>
        <div class="g--flex-around">
          <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path class="cls-1" d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10z"/></svg>
          <div class="gdpr-item-text">Our processes are transparent and open to audit. Our terms of service provides a binding contract that dictates our commitment and responsibility for your data, pursuant to Article 28.</div>
        </div>
        <div class="g--flex-around">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="80" height="80" viewBox="0 0 24 24"><defs><path id="a" d="M0 0h24v24H0V0z"/></defs><clipPath id="b"><use xlink:href="#a" overflow="visible"/></clipPath><path class="cls-1" d="M1 21h12v2H1zM5.245 8.07l2.83-2.827 14.14 14.142-2.828 2.828zM12.317 1l5.657 5.656-2.83 2.83-5.654-5.66zM3.825 9.485l5.657 5.657-2.828 2.828-5.657-5.657z" clip-path="url(#b)"/></svg>
          <div class="gdpr-item-text">We only process your information in the manner as described and as instructed. Our terms of use are strict for <b>your</b> protection and we have a dedicated focus to full compliance.</div>
        </div>
      </div>

      <div class="g--pad-t-10">

        <div class="g--text-center" v-if="!tech_details">
          <svg v-on:click="tech_details=true" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path class="cls-1z" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"/></svg>
        </div>

        <div class="g--text-center" v-if="tech_details">
          <svg v-on:click="tech_details=false" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path class="cls-1z" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11H7v-2h10v2z"/></svg>
        </div>

        <div v-if="tech_details" class="animated fadeIn faster">
          <div class="g--text-center">
            <h2 class="g--text-white g--text-center l--gdpr-header">Technical Product Details</h2>
          </div>
          <div class="g--text-center">
            <p class="g--text-white">This information is provided to address internal compliance questions for full transparency.</p>
          </div>

          <h2 class="g--text-white">Web server</h2>
          <p>Eventin operates a <b>Python-Flask backend</b>, supported by the Flask-Login user authentication library. Other companies using the same and similar technologies, alongside 100s of others, include:</p>
          <div class="g--flex-around">
            <div class="l--company-img">
              <img width="46" height="46" src="@/static/images/icons/tech_netflix.png">
            </div>
            <div class="l--company-img">
              <img width="46" height="46" src="@/static/images/icons/tech_instagram.png">
            </div>
            <div class="l--company-img">
              <img width="46" height="46" src="@/static/images/icons/tech_lyft.png">
            </div>
            <div class="l--company-img">
              <img width="46" height="46" src="@/static/images/icons/tech_pinterest.png">
            </div>
            <div class="l--company-img">
              <img width="46" height="46" src="@/static/images/icons/tech_reddit.png">
            </div>
            <div class="l--company-img">
              <img width="46" height="46" src="@/static/images/icons/tech_coursera.jpeg">
            </div>
          </div>
          <p>Our webservers and runtime environment are operated and maintained by Heroku.</p>

          <h2 class="g--text-white">Password and Security Policy</h2>
          <p>Our password policies are determined by recommendations from the Internet Engineering Task Force, which publish and research many technical areas
              related to internet operation and security. In particular see: <a class="g--text-white" href="https://tools.ietf.org/html/rfc8018" target="_blank">Password-Based Cryptography Specification</a>.</p>
          <p>Personally identifiable information relating to you or your clients is encrypted with 128bit AES encryption before being transmitted to our database servers. Additionally, all database
              information is further encrypted in situ meaning personally identifiable information is doubly-encrypted.</p>
          <p>Our distributable cloud event URLs are protected by 160bit SHA-1 hash signatures, which are considerably stronger than any typical user generated passwords.</p>

          <h2 class="g--text-white">Database</h2>
          <p>Our Postgres relational database services are managed by Amazon Web Services (AWS). Other companies, alongside 1000s of others, using the same technology are:</p>
          <div class="g--flex-around">
            <div class="l--company-img">
              <img width="46" height="46" src="@/static/images/icons/tech_netflix.png">
            </div>
            <div class="l--company-img">
              <img width="46" height="46" src="@/static/images/icons/tech_airbnb.jpeg">
            </div>
            <div class="l--company-img">
              <img width="46" height="46" src="@/static/images/icons/tech_coursera.jpeg">
            </div>
            <div class="l--company-img">
              <img width="46" height="46" src="@/static/images/icons/tech_circle_ci.png">
            </div>
            <div class="l--company-img">
              <img width="46" height="46" src="@/static/images/icons/tech_asana.png">
            </div>
            <div class="l--company-img">
              <img width="46" height="46" src="@/static/images/icons/tech_intuit.png">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="distribution"  class="l--white g--pad-t-10 g--pad-b-10">

      <h1 style="margin-top: 0">Distribution on all Devices</h1>

      <h2 class="g--text-center">Desktops, Phones and Tablets</h2>

      <div style="width:100vw">
        <img class="l--dist-img" src="@/static/images/distribution.jpg"/>
      </div>

      <div class="g--text-center g--pad-t-2">
        <div class="btn-sign-up btn-sign-up--inv" v-on:click="nav_click_blank('/schedule-test')">
          VIEW DEMO SCHEDULE
        </div>
      </div>

    </div>

    <div id="screenshots2" class="container--banner phone-no-show g--bg-lingrad">
      <h1 class="h1--white" style="margin-top: 0">Rapid Workflow</h1>
      <h2 class="h1--white g--text-center">Simple and efficient UI dashboard..</h2>
      <div class="g--text-center">
        <img src="@/static/images/ss_dashboard.png"/>
      </div>
      <h2 class="h1--white g--text-center">Add items in 2-clicks..</h2>
      <div class="g--text-center">
        <img src="@/static/images/ss_form.png"/>
      </div>
      <h2 class="h1--white g--text-center">Customise schedule design for clients..</h2>
      <div class="g--text-center">
        <img src="@/static/images/ss_customise.png"/>
      </div>
      <h2 class="h1--white g--text-center">PDF printing is pre-configured too..</h2>
      <div class="g--text-center">
        <img src="@/static/images/ss_print.png"/>
      </div>
      <h2 class="h1--white g--text-center">And many more features..</h2>
      <div class="g--pad-t-2">
        <div class="btn-sign-up" v-on:click="nav_click('/register/organiser')">
          GET STARTED
        </div>
      </div>
    </div>

    <div id="pricing" class="container--banner l--light">
      <h1 style="margin-top:0;">Pricing</h1>

      <div class="g--flex-around  g--flex--align-start">

        <div class="g--flex-around--col banner-el--max-width banner-box">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="150" height="150" viewBox="0 0 24 24"><defs><path id="a" d="M0 0h24v24H0V0z"/></defs><clipPath id="b"><use xlink:href="#a" overflow="visible"/></clipPath><path class="cls-1" d="M9 11.24V7.5C9 6.12 10.12 5 11.5 5S14 6.12 14 7.5v3.74c1.21-.81 2-2.18 2-3.74C16 5.01 13.99 3 11.5 3S7 5.01 7 7.5c0 1.56.79 2.93 2 3.74zm9.84 4.63l-4.54-2.26c-.17-.07-.35-.11-.54-.11H13v-6c0-.83-.67-1.5-1.5-1.5S10 6.67 10 7.5v10.74l-3.43-.72c-.08-.01-.15-.03-.24-.03-.31 0-.59.13-.79.33l-.79.8 4.94 4.94c.27.27.65.44 1.06.44h6.79c.75 0 1.33-.55 1.44-1.28l.75-5.27c.01-.07.02-.14.02-.2 0-.62-.38-1.16-.91-1.38z" clip-path="url(#b)"/></svg>
          <div class="g--text-center" style="width:100%">
            <h2>Free to Try</h2>
            <p>
              <b>If you are the first person in your organisational department to try Eventin, you can create your first
                event for free.</b>
            </p>
          </div>
        </div>

        <div class="g--flex-around--col banner-el--max-width banner-box">
          <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path class="cls-1" d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z"/></svg>
          <div class="g--text-center" style="width:100%">
            <h2>From £20</h2>
            <p>
              <b>You can create and distribute events from as little as £20 per event (based on usage), regardless of the size or number of attendees.</b>
            </p>
          </div>
        </div>

        <div class="g--flex-around--col banner-el--max-width banner-box">
          <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 24 24"><path d="M24 0H0v24h24z" fill="none"/><path class="cls-1" d="M17.66 7.93L12 2.27 6.34 7.93c-3.12 3.12-3.12 8.19 0 11.31C7.9 20.8 9.95 21.58 12 21.58c2.05 0 4.1-.78 5.66-2.34 3.12-3.12 3.12-8.19 0-11.31zM12 19.59c-1.6 0-3.11-.62-4.24-1.76C6.62 16.69 6 15.19 6 13.59s.62-3.11 1.76-4.24L12 5.1v14.49z"/></svg>
          <div class="g--text-center" style="width:100%">
            <h2>Flexible</h2>
            <p>
              <b>We have subscription based payment, or direct bill for pay as you go usage. You can pay directly by card or choose to receive an invoice.</b>
            </p>
          </div>
        </div>

      </div>

      <div class="g--pad-t-2">
        <div class="btn-sign-up btn-sign-up--inv" v-on:click="nav_click('/register/organiser')">
          GET STARTED
        </div>
      </div>

    </div>

    <div data-nosnippet id="contact" class="container--banner l--dark g--flex-start g--flex--col-nowrap g--flex--align-start">
      <p> You can contact us at <b>support@eventin.io</b> .</p>

      <p> Eventin.io is a product developed and operated by Siffrorna Technologies Limited.</p>

      <div style="flex-grow:1"></div>

      <h1 class="h1--white" style="margin-top: 0">System Requirements</h1>

      <div id="requirements" class="">
        <div class="requirements-item g--text-left">
          <h2>For Attendees</h2>
          <p class="g--text-white">Eventin is designed to provide the <b>greatest flexibility</b> for your attendees, whilst still
          maintaining a smooth and stylised event schedule.
          <p class="g--text-white">Schedules display correctly on almost all mobile devices running <b>Andriod or iOS</b> as well as many
          customised mobile operating systems in their local browsers.</p>
          <p class="g--text-white">On desktop computers schedules display correctly in all major browsers including <b>Internet Explorer, Opera, Chrome, Firefox, Safari, and Edge</b>.
            We support browser versions of these software dating back to 2013. However, older versions may incorrectly display fonts, colours or position of elements.</p>
          <p class="g--text-white">We are working hard to make attendee schedules backwards compatible with older and customised browser versions on all platforms.</p>
          <p class="g--text-white">You can use the above <a target="_blank" href="/schedule-test">demo schedule link</a> to test the display of our schedules in any browser on any platform.</p>
        </div>
        <div class="requirements-item g--text-left">
          <h2>For Organisers</h2>
          <p class="g--text-white">Organisers use our platform to input and coordinate data. It is recommended to use
          a desktop PC with a minimum resolution of 1100x800 pixles for optimal visibility. <b>We guarantee our platform
          works in modern browsers</b> with following specifications and enabled Javascript (ECMA Script6). We make every effort to provide backwards compatibility for
          older browsers and Javascript versions but cannot guarantee full functionality for all cases, due to extensive coordination features that Eventin offers:</p>
          <div>
            <span style="color:green;font-weight:bold;">full support</span> / <span style="color:orange">partial compatibility</span>
          </div>
          <div class="g--flex-start g--text-white">
            <img src="@/static/images/sys_req.png"/>
            <div style="height:162px;line-height:27px;">
              Mozilla Firefox <span style="color:green;font-weight:bold;"> >54</span> <span style="color:orange">(>28)</span> <br/>
              Google Chrome <span style="color:green;font-weight:bold;"> >51</span> <span style="color:orange">(>23)</span><br/>
              Apple Safari <span style="color:green;font-weight:bold;"> >10</span> <span style="color:orange">(>6.1)</span><br/>
              Opera <span style="color:green;font-weight:bold;"> >38</span> <span style="color:orange">(>15)</span><br/>
              Microsoft Edge <span style="color:green;font-weight:bold;"> all</span> <br/>
              <span style="text-decoration:line-through">Microsoft Explorer </span><span style="color:green;font-weight:bold;text-decoration: line-through">none</span><br/>
            </div>
          </div>
        </div>
      </div>

      <p>&copy; Siffrorna Technologies 2019</p>

    </div>

  </div>
</template>

<script>
import TopNavLinks from '@/components/utils/TopNavLinks.vue'
import TopNavLinksRight from '@/components/utils/TopNavLinksRight.vue'
import BackgroundNetwork from '@/components/utils/BackgroundNetwork.vue'
export default {
  name: 'Index',
  components: {
    TopNavLinks, TopNavLinksRight, BackgroundNetwork
  },
  props: {
    'backend_current_user': Object,
    'backend_id': String,
    'backend_stats': Object
  },
  data: function () {
    return {
      src: './assets/img_events.png',
      image_gallery: null,
      tech_details: false
    }
  },
  methods: {
    nav_click: function (url) {
      // eslint-disable-next-line no-undef
      window.location = $WEB_SCRIPT_ROOT + url
    },
    nav_click_blank: function (url) {
      // eslint-disable-next-line no-undef
      window.open($WEB_SCRIPT_ROOT + url, '_blank')
    }
  },
  mounted: function () {
    if (this.backend_id !== null) {
      const el = document.getElementById(this.backend_id)
      window.setTimeout(function () { el.scrollIntoView() }, 250)
    }
  }
}
</script>

<style lang="stylus">
.container--heading
  width 100%
  /*height 66vh*/
  overflow hidden
  padding-bottom 5rem

.l--header-spacer
  height:100px;
  width:100%

// Maximum aspect ratio
@media (max-aspect-ratio: 1/1)
  .container--heading
    /*height 66vw*/

.container--banner
  width 100%
  min-height 80rem
  padding 13rem 5rem
  box-sizing border-box
  color var(--text-4)

.banner-el--max-width
  width 250px

.l--white
  background-color  white
  color var(--text-3)

.l--light
  background-color var(--bg-3)
  color var(--text-3)

.l--dark
  // background-color var(--bg-1)
  color var(--text-1)

.gdpr-item-text{
  width:50vw;
  min-width:300px;
  margin-left:4rem;
  margin-bottom:3rem;
  margin-top:3rem;
}

.screenshots__item
  width:200px
  height:150px
  margin:auto
  text-align:center
  &:hover
    cursor zoom-in

.screenshots__item--big
  width:800px
  /*height:119px*/
  margin:2rem
  text-align:center
  &:hover
    cursor zoom-out

.l--scrollable
  &:hover
    cursor ns-resize

.requirements-item{
  width:40vw;
  min-width: 300px;
  padding-bottom: 4rem;
}

.l--dual_container {
  width: 40vw;
  padding: 5rem 0;
  position:relative;
}
@media only screen and (max-width: 479px) {
  .l--dual_container {
    width: 60vw;
  }
  .container--heading {
    height: unset;
  }
  .l--header-spacer {
    display none
  }
}

.l--gdpr-header
  background-color var(--c-dark)
  border-radius 1rem
  display inline-block
  padding 2rem

.cloud-icon {
  position: absolute;
  top:-16vw;
  left:-10vw;
  color:rgba(255,255,255,0.05);
}
.cloud-icon--font {
  font-size:calc(80vw)
}
.logo
  width:50%
  z-index:2

.logo-text
  width:75%
  color: var(--c2)
  position:absolute
  left: calc(12.5%)
  top: 75%
  text-align: center
  font-size: 2.0rem
  padding: 2rem
  font-family: 'Work Sans', sans-serif

strong
  font-weight bold

h1 {
  font-size: 5.2rem;
  font-family: 'Work Sans', sans-serif;
  text-align: center;
}
.h1--white {
  color: white
}

.color-bar {
    border-top: 2px solid var(--c);
}
.color2-bar {
    border-top: 2px solid var(--c2);
}
.colorw-bar {
    border-top: 2px solid white;
}
.colorb-bar {
    border-top: 2px solid var(--text-3);
}

.color{
    color: var(--c);
}
.color2 {
    color: var(--c2);
}

.text-heading{
  font-family: 'Work Sans', sans-serif;
  color: var(--c);
  font-size:3rem;
}

.cls-1{fill:var(--c2);}
.cls-2{fill:#c6c6c6;}
.cls-3{font-size:103.95px;fill:#151515;font-family:Myriad Pro;font-weight:700;font-style:italic;}
.cls-1a{fill:var(--c2-dark);}
.cls-1b{fill:var(--c);}
.cls-1c{fill:var(--c-dark);}
.cls-1z
  fill var(--c2)
  transition 0.3s
  &:hover
    cursor pointer
    fill var(--c2-dark)

#contact
  height calc(100vh)

.scheme-lingrad {
  background-color: var(--bg-1);
  background-image: linear-gradient(to right, var(--left-gradient), var(--right-gradient));
  color: var(--text-1)
}

.banner-box
  background white
  padding 2rem
  border 1px solid var(--white-hover)
  border-radius 1rem
  /*box-shadow 0px 5px 10px var(--c)*/
  margin: 2rem 0;

.l--dist-img
  width:100%

.l--company-img
  height: 46px
  border-radius 4px
  overflow: hidden

.l--quote
  padding: 2rem;
  margin: 2rem 0;
  margin-top: 5rem;
  width: 80%;
  box-sizing: border-box;
  border-radius: 1rem;
  font-size: 2.1rem;
  font-style: italic;
  text-align: left;
  position relative

</style>
