<template>
  <div class="image-bg">
    <img class="image-size" src="@/static/images/bg-shade3.png"/>
  </div>
</template>

<script>
export default {
  name: 'BackgroundNetwork'
}
</script>

<style scoped>
.image-bg {
  width:100vw;
  height:100vh;
  overflow:hidden;
  position:fixed;
  z-index:-1;
  top:0;
  left:0;
}

.image-size{
  width:100%
}

  /* Maximum aspect ratio */
@media (max-aspect-ratio: 1600/837) {
  .image-size {
    width:unset;
    height: 100%;
  }
}
</style>
