import Vue from 'vue'
import Index from '@/components/Index.vue'
import cssVars from 'css-vars-ponyfill' // <- for IE9+

// Vue.config.productionTip = false

// Vue.config.devtools = true

new Vue({
  render: function (h) {
    return h(Index, {
      props: {
        // backenddata: this.$el.attributes.backend.value,
        // backendjson: JSON.parse(this.$el.attributes.backendjson.value)
        // eslint-disable-next-line no-undef
        backend_current_user: $('#vue-container').data('backend_current_user'),
        // eslint-disable-next-line no-undef
        backend_id: $('#vue-container').data('backend_id'),
        // eslint-disable-next-line no-undef
        backend_stats: $('#vue-container').data('backend_stats')
      }
    })
  }
}).$mount('#vue-container')

cssVars({
  // Targets
  rootElement: document,
  shadowDOM: false,

  // Sources
  include: 'link[rel=stylesheet],style',
  exclude: '',
  variables: {},

  // Options
  onlyLegacy: true,
  preserveStatic: true,
  preserveVars: false,
  silent: false,
  updateDOM: true,
  updateURLs: true,
  watch: false
})
