<template>
  <div class="g--flex-end g--pos-0r g--pad-r-2 g--bg-none --z-priority" v-bind:style="{position: position}">
    <template v-for="(object, o_index) in objects">
      <div v-on:click="nav_click(object[1])" v-bind:key="'navm_' + o_index" class="nav-link">
        {{ object[0] }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'TopNavLinksRight',
  props: {
    position: String,
    objects: Array
  },
  methods: {
    nav_click: function (url) {
      // eslint-disable-next-line no-undef
      window.location = $WEB_SCRIPT_ROOT + url
    }
  }
}
</script>

<style scoped>
.nav-link {
  margin:2rem;
  width: 6rem;
  height: 6rem;
  color: var(--text-1);
  border: 1px solid var(--text-1);
  border-radius: 3rem;
  line-height: 6rem;
  text-align:center;
  font-weight: bold;
  text-decoration: unset;
}
.nav-link:hover{
  background-color: var(--text-1-s);
  color: var(--bg-1);
  text-decoration: unset;
  cursor: pointer;
  transition: background-color 0.2s ease,
              color 0.2s ease;
}
.--z-priority {
  z-index: 9999;
}
</style>
